<template>
    <div id="fashion" class="univers">
        <Header v-if="$route.name != 'index'" :nbProductsCart="nbProductsCart"></Header>
        <router-view :key="$route.fullPath"></router-view>
        <ModalIdentification v-if="modalShow"></ModalIdentification>
        <Loader :content="$t('loading')" v-if="showLoader"></Loader>
        <b-modal id="shareModal" ref="shareModal" hide-footer centered :title="$t('sharePopinTitle')">
            <div class="modal-body">
                <img class="bigQrCode" :src="qrCodeImg"/>
            </div>
        </b-modal>
        <ModalCrossSelling :productCrossSell="productCrossSell"></ModalCrossSelling>
        <ModalCabin v-if="modalCabinShow"></ModalCabin>
        <Alert ref="Alert" :alertContent="alertContent" :alertVariant="alertVariant"></Alert>
    </div>
</template>

<script>
    import "@/assets/utils/addtohomescreen.css"
    import "@/assets/utils/addtohomescreen.js"
    import Header from "@/components/Fashion/Header";
    import ModalIdentification from "@/components/Fashion/ModalIdentification";
    import Loader from "@/components/Common/Loader";
    import ModalCrossSelling from "@/components/Fashion/ModalCrossSelling";
    import Alert from "@/components/Common/Alert";
    import {isNotNull} from "@/assets/js/Utils"
    import ModalCabin from "@/components/Fashion/ModalCabin";
    var Basket = require("@/assets/js/Basket");
    var qrcodeGenerator = require('qrcode-generator')
    var App = require("@/assets/js/App")
    var Stats = require("@/assets/js/Stats")
    var Utils = require("@/assets/js/Utils")
    var MQTT = require("@/assets/js/MqttService")
    var Customer = require("@/assets/js/Customer")

    export default {
        name: "Fashion",
        components: {ModalCabin, Header,ModalIdentification,Loader,ModalCrossSelling,Alert},
        data: function() {
            return{
                basket: {
                    "id": 0,
                    "totalAmount": 0,
                    "products": [],
                    "lockedProducts" :[]
                },
                nbProductsCart : 0,
                qrCodeImg :'',
                showLoader: false,
                modalShow: false,
                base: "",
                productCrossSell: null,
                alertContent: "",
                alertVariant: "",
                logoURL: "",
                customer: null,
                modalCabinShow: false
            }
        },
        created(){
            let i18n = {};
            Object.keys(this.langs).forEach( lang => {
                    i18n[lang] = require('@/locales/'+lang+'.json');
                    this.$root.$i18n.setLocaleMessage(lang,i18n[lang].fashion)
                }
            );

            if(isNotNull(localStorage.getItem("DKC_lang"))){
                this.$root.$i18n.locale = localStorage.getItem("DKC_lang");
            }else{
                this.$root.$i18n.locale = 'en';
            }

            if(isNotNull(localStorage.getItem("DKC_complexId")) && isNotNull(localStorage.getItem("DKC_demoId"))){
                Utils.checkToken();
            }

            var token = localStorage.getItem("DKC_fashion_token");
            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                Utils.clearCacheExceptCustomer();
                if(isNotNull(token)){
                    localStorage.setItem("DKC_needPublishPoster", "true");
                }
            }

            App.saveDeviceType();
            App.checkParameters(window.location.pathname, this);
            this.setBase();

            if(!isNotNull(window.location.href.indexOf("needParameters")) && !isNotNull(window.location.href.indexOf("ticketId")) && !isNotNull(token)){
                if(this.$route.name != 'index'){
                    this.$router.push(this.base);
                }else{
                    this.modalShow = true;
                }
            }

            this.refreshCustomer();

            this.basket = Basket.getBasket();

            if(isNotNull(localStorage.getItem("DKC_productCrossSell"))){
                this.productCrossSell = JSON.parse(localStorage.getItem("DKC_productCrossSell"));
            }

            window.onunload = function(){
                this.closeHandler();
            }
        },
        mounted(){
            Utils.setAppContext(this);
            MQTT.susbscribeReset(this);

            var needReferesh = localStorage.getItem("DKC_needRefresh");
            var needPublishPoster = localStorage.getItem("DKC_needPublishPoster");

            if(isNotNull(window.location.pathname.indexOf("DKS"))){
                needReferesh = "true";
            }

            if(!needReferesh){
                this.setStats();
            }

            if(isNotNull(needPublishPoster) && needPublishPoster == "true"){
                MQTT.publishMessage("SCAN_POSTER", "", () => {
                    if(isNotNull(needReferesh) && needReferesh == "true"){
                        needReferesh = "false";
                        window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
                    }
                });
            }else if(isNotNull(needReferesh) && needReferesh == "true"){
                window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
            }
            localStorage.removeItem("DKC_needPublishPoster");
            localStorage.removeItem("DKC_needRefresh");
        },
        beforeUpdate(){
            MQTT.unsusbscribeReset();
        },
        updated(){
            MQTT.susbscribeReset(this);
            if(!isNotNull(this.customer)){
                var token = localStorage.getItem("DKC_fashion_token");
                console.log(isNotNull(token))
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            }
        },
        methods: {
            basketChange: function(){
                this.basket = Basket.getBasket();
                this.count(this.basket.products);
            },
            count: function(products) {
                var count = 0;
                products.forEach(function(product) {
                    count+=product.qty;
                });
                this.nbProductsCart = count;
            },
            closeHandler:function() {
                var self = this;
                if(isNotNull(localStorage.getItem("DKC_fashion_token"))){
                    Basket.updateBasket(function(newBasket) {
                        let tmpProducts = self.basket.products;
                        newBasket.products.forEach(function(element) {
                            tmpProducts.some(function(oldProduct, id) {
                                if(element.product.ean == oldProduct.product.ean && oldProduct.qtyLocked && oldProduct.qty == element.qty){
                                    element.qtyLocked = oldProduct.qtyLocked;
                                    tmpProducts.splice(id, 1)
                                    return true;
                                }
                            })
                        })
                        newBasket.lockedProducts = self.basket.lockedProducts;
                        self.basket = newBasket;
                        localStorage.setItem("DKC_basket", JSON.stringify(self.basket));
                    })
                }
            },
            share: function(){
                var qr = qrcodeGenerator(0, 'L');
                qr.addData(window.location.origin + localStorage.getItem("DKC_base"));
                qr.make();
                this.qrCodeImg = qr.createDataURL();
                this.$refs.shareModal.show();
            },
            setBase: function(){
                var referer = "DKC";
                var univers = localStorage.getItem("DKC_univers");
                var demoId = localStorage.getItem("DKC_demoId");
                var scanType = localStorage.getItem("DKC_shortScanType");
                var complexIdParent = localStorage.getItem("DKC_complexId");

                if(isNotNull(univers) && isNotNull(demoId) && isNotNull(scanType)){
                    if(isNotNull(complexIdParent)){
                        this.base = "/fashion/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType + "_" + complexIdParent;
                    }else{
                        this.base = "/fashion/p=" + referer + "_" + univers + "_" + demoId + "_" + scanType;
                    }
                }
                localStorage.setItem("DKC_base", this.base);
            },
            showAlert(content, type = ""){
                var variant = "";
                switch (type) {
                    case 'success':
                        variant = "success";
                        break;
                    case 'error':
                        variant = "danger";
                        break;
                    case 'info':
                        variant = "info";
                        break;
                    default:
                        variant = "info";
                }
                this.alertContent = content;
                this.alertVariant = variant;
            },
            checkDemonstrationLogo(){
                var path = Utils.getConfig().PATH_IMG + localStorage.getItem("DKC_demoId");
                this.logoURL = Utils.getConfig().DKW_URL + path;
            },
            refreshCustomer(){
                var self = this;
                var token = localStorage.getItem("DKC_fashion_token");
                console.log(isNotNull(token))
                if(isNotNull(token)){
                    Customer.getCustomer(function(jsonCustomer){
                        if(isNotNull(jsonCustomer)){
                            self.customer = JSON.parse(jsonCustomer);
                        }
                    });
                }
            },
            setStats(){
                var complexId = localStorage.getItem("DKC_complexId");
                if(isNotNull(complexId) && window.location.href.indexOf("needParameters") == -1 && this.$route.name == "index"){
                    var complexIdParent = localStorage.getItem("DKC_complexIdParent");
                    var deviceType = localStorage.getItem("DKC_deviceType");
                    var parameters = {
                        nbPlayedDemos: 1
                    }

                    if(isNotNull(complexIdParent) && isNotNull(complexId) && complexIdParent != complexId)
                        parameters.complexIdParent = complexIdParent;

                    if(isNotNull(deviceType))
                        parameters.deviceType = deviceType;

                    Stats.setStats(parameters);
                }
            }
        },
        watch:{
            basket: function() {
                //todo ?
                localStorage.getItem("DKC_basket");
                this.count(this.basket.products);
                this.$emit('chgtQty', this.nbrProducts);
            },
            '$route': {
                handler: function() {
                    this.closeHandler();
                },
                deep: true,
                immediate: true
            }
        }
    }
</script>

<style scoped>

</style>