<template>
    <header class="header" v-if="!$route.query.needParameters">
        <div class="header__title">
            <img @click="redirect" :src="$parent.logoURL" id="headerLogo"/>
        </div>

        <div id="share" @click="$parent.share">
            <img src="@/assets/img/share_black.png" id="pictoShare"/>
        </div>


        <router-link id="cart" tag="div" class="" :to="this.base + '/basket'">
            <img src="@/assets/img/picto_cart.png" id="pictoCart"/>
            <span v-show="nbProductsCart>0" id="nbProductsCart">{{nbProductsCart}}</span>
        </router-link>

    </header>
</template>

<script>
    export default {
        name : 'Header',
        props : ['nbProductsCart'],
        data(){
            return{
                base: localStorage.getItem("DKC_base")
            }
        },
        methods: {
            redirect: function(){
                this.$parent.closeHandler();
                this.$router.push(localStorage.getItem("DKC_base"));
                //window.location.replace(window.location.origin + localStorage.getItem("DKC_base"));
            }
        },
        created(){
            this.$parent.checkDemonstrationLogo();
        },
        watch:{
            nbProductsCart: function() {
                document.getElementById('cart').classList.add("shake");
                setTimeout(function() {
                    document.getElementById('cart').classList.remove("shake");
                }, 1000)
            }
        }
    }
</script>